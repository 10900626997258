import React from "react"
import SinglePageHeader from "../components/SinglePageLayout"
import { useTranslation } from "gatsby-plugin-react-i18next"

export default function Careers() {
  const { t } = useTranslation()

  return (
    <SinglePageHeader
      title={t("contact-us")}
      bg="/assets/img/contact/header.jpg"
    >
      <section className="section-map section-map-left" id="contact-section">
        <div className="google-map google-map-static hidden-xs hidden-sm"></div>
        <div className="container">
          <div className="title">
            <h3 style={{ color: "#082e5b" }}>{t("contact-us")}</h3>
          </div>
          <ul className="contact-section">
            <li>
              <span className="label">{t("email")}</span>
              <span className="value">
                <a href="mailto:contact@ich-group.com">contact@ich-group.com</a>
              </span>
            </li>
            <li>
              <span className="label">{t("phone")}</span>
              <span className="value">+65 6846 1800</span>
            </li>
            <li>
              <span className="label">{t("fax")}</span>
              <span className="value">+65 6846 1900</span>
            </li>
            <hr />
            <div className="bottom-address">
              <p>
                9 Temasek Boulevard #38-02 Suntec City Tower 2,
                <br />
                Singapore 038989
              </p>
            </div>
          </ul>
        </div>
      </section>
    </SinglePageHeader>
  )
}
